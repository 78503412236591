var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { id: "maintenancePlanContainer" } },
    [
      _c(
        "v-col",
        [
          _c(
            "v-sheet",
            { attrs: { "max-width": _vm.$vuetify.breakpoint.xs ? 310 : 610 } },
            [
              _c(
                "v-slide-group",
                {
                  attrs: {
                    "mobile-breakpoint": "600",
                    elevation: "0",
                    multiple: "multiple",
                    "show-arrows": false,
                  },
                  model: {
                    value: _vm.model,
                    callback: function ($$v) {
                      _vm.model = $$v
                    },
                    expression: "model",
                  },
                },
                [
                  _c(
                    "v-slide-item",
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "ma-4 pa-4",
                          attrs: {
                            color: "white",
                            "min-height": "300",
                            "min-width": "300",
                          },
                        },
                        [
                          _c("v-card-title", [
                            _c("h3", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("BenefitsOfOurMobile")) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("LandMaintenancePlan")) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "fill-height",
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "ul",
                                      {
                                        staticStyle: {
                                          "margin-left": "-8px",
                                          "font-size": "0.860em",
                                        },
                                      },
                                      [
                                        _c(
                                          "li",
                                          { staticStyle: { color: "#003852" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "WeWillSendYouRemindersForOil"
                                                )
                                              )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("ChangeViaTheApp")
                                                )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticStyle: { color: "#003852" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "600",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("TenPerOff"))
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "AllMaintenanceWorkForAs"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "LongAsYouOwnTheCarIfScheduled"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "ThroughTheAppThisDiscountIs"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "TransferableToYourBuyerIfYouSell"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "YourVehicleAsLongAsNewOwner"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "EnrollsInTheUcarMobileMaintenance"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Program")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticStyle: { color: "#003852" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "SinceWeAreMobileOurServicesAre"
                                                )
                                              ) + " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "600",
                                                },
                                              },
                                              [_vm._v("30")]
                                            ),
                                            _c("br"),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "600",
                                                },
                                              },
                                              [_vm._v("to 40%")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("BelowTheIndustry")
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("AveragePricing")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticStyle: { color: "#003852" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("AllWorkGuaranteedFor")
                                              ) + " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "600",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("Months")))]
                                            ),
                                            _vm._v(" or"),
                                            _c("br"),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "600",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.$t("Miles")))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticStyle: { color: "#003852" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "MajorRepairsAreDoneAtAFixed"
                                                )
                                              )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "LocationAtOneOfOurAffiliates"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("RepairFacilitates")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticStyle: { color: "#003852" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "TowingBySchedulingThrough"
                                                )
                                              )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("UcarMobileApp")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "600",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Rates30PerBelowAAA")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticStyle: { color: "#003852" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "WeKeepAllYourMaintenanceAvailable"
                                                )
                                              )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "ToYouFromTheAppHavingThese"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "RecordsWillMaintainTheValueOfYour"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "VehicleAndMakeItEasierToSell"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticStyle: { color: "#003852" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "IfYouDecideToSellDontTradeSell"
                                                )
                                              )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "ItByConsignmentToAPrivateParty"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "YourDealerWillDisplayTheCarIn"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "HisLotAdvertiseAndNegotiateOn"
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("YourBehalf")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-slide-item",
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "ma-4 pa-4",
                          attrs: {
                            color: "white",
                            "min-height": "300",
                            "min-width": "300",
                          },
                        },
                        [
                          _c("v-card-title", { staticClass: "ma-0" }, [
                            _c("h3", { staticClass: "ma-0" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("OurMobileAutoRepair")) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " + _vm._s(_vm.$t("MaintenanceService")) + " "
                              ),
                            ]),
                          ]),
                          _c(
                            "v-card-text",
                            _vm._l(_vm.items, function (item, i) {
                              return _c(
                                "v-row",
                                { key: i },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          width: item.width,
                                          src: item.avatar,
                                          alt: item.avatar,
                                        },
                                      }),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#003852" } },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-slide-item",
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "ma-4 pa-4",
                          attrs: {
                            color: "white",
                            "min-height": "300",
                            "min-width": "300",
                          },
                        },
                        [
                          _c("v-card-title", { staticClass: "ma-0" }, [
                            _c("h3", { staticClass: "ma-0" }, [
                              _vm._v(_vm._s(_vm.$t("HowDoesUcarMobileWork"))),
                            ]),
                          ]),
                          _c("v-card-text", [
                            _c("div", [
                              _c("div", { staticClass: "text-last-column" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "RepairingYourCarShouldBeEasyRight"
                                      )
                                    )
                                ),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("AndWeMakeThisPossibleByHavingA")
                                    )
                                ),
                                _c("br"),
                                _vm._v(" " + _vm._s(_vm.$t("Simple")) + " "),
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "600" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("ThreeStepProcess")) + " "
                                    ),
                                  ]
                                ),
                                _vm._v(" " + _vm._s(_vm.$t("ThatMakes")) + " "),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "MobileAutoServiceExperienceSeamless"
                                      )
                                    )
                                ),
                                _c("br"),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("AndHassleFree")) + " "
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "mt-6" },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      "max-width": 80,
                                      alt: "Choose Car",
                                      src: require("../../src/assets/images/icons/choose-car.png"),
                                    },
                                  }),
                                  _c("div", [
                                    _c(
                                      "h3",
                                      { staticClass: "header-last-column" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("ChooseYourCarService"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "text-last-column" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "SelectTheServiceYouWantOurCertified"
                                              )
                                            )
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "MobileMechanicTechnicianToPerform"
                                              )
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mt-6" },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      "max-width": 55,
                                      alt: "Calendar",
                                      src: require("../../src/assets/images/icons/calendar.png"),
                                    },
                                  }),
                                  _c("div", [
                                    _c(
                                      "h4",
                                      { staticClass: "header-last-column" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("BookAnAppointment"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "text-last-column" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "PickATimeAndPlaceThatWorksForYou"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mt-6" },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      "max-width": 70,
                                      alt: "Sit Back and Relax",
                                      src: require("../../src/assets/images/icons/sit-back-relax.png"),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { "max-width": "280px" } },
                                    [
                                      _c(
                                        "h4",
                                        { staticClass: "header-last-column" },
                                        [_vm._v(_vm._s(_vm.$t("SitBackRelax")))]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "text-last-column" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "WeWillSendYouTheConfirmationAnd"
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-width": "1px 8px 1px 1px",
                                  "border-style": "solid",
                                  "border-color":
                                    "rgb(0, 56, 82) rgb(33, 181, 115) rgb(0, 56, 82) rgb(0, 56, 82)",
                                  padding: "15px 0px 0px 10px",
                                  "margin-top": "40px",
                                },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      color: "#003852",
                                      "font-weight": "600",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("DownloadTheApp")) + " "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#21b573",
                                          "margin-left": "2px",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Ucarmobile")))]
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "col-xs-10 col-sm-10 col-md-6",
                                      attrs: {
                                        href: "https://apps.apple.com/ie/app/ucar-mobile-car-care-repair/id1495701232",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          width: "100",
                                          src: require("../assets/images/icons/download-apple.svg"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "col-xs-10 col-sm-10 col-md-6",
                                      attrs: {
                                        href: "https://play.google.com/store/apps/details?id=com.uCarMobile.obdconnect",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          width: "100",
                                          src: require("../assets/images/icons/download-google.svg"),
                                          alt: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticClass: "mb-0",
                                    staticStyle: { color: "#003852" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("TryItOnOurWebsite")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      "text-decoration": "none",
                                      color: "#003852",
                                      "font-weight": "600",
                                      "margin-left": "2px",
                                    },
                                    attrs: {
                                      href: "https://ucarmobile.com/",
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(" www.ucarmobile.com ")]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-slide-item",
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "ma-4 pa-4",
                          attrs: {
                            color: "white",
                            "min-height": "300",
                            "min-width": "300",
                          },
                        },
                        [
                          _c("v-card-title", { staticClass: "ma-0" }, [
                            _c("h3", { staticClass: "ma-0" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("HowToInstallAndEnroll")) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                { staticStyle: { "max-width": "300px" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "align-center col-12" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(_vm.$t("InstallAndEnroll"))
                                            ),
                                          ]),
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-3",
                                            attrs: {
                                              width: "200",
                                              src: require("../assets/images/sampleKeytag.png"),
                                            },
                                          }),
                                          _c(
                                            "p",
                                            { staticClass: "text-center" },
                                            [_vm._v("sample keytag")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "col-12",
                                      staticStyle: { "max-width": "300px" },
                                    },
                                    [
                                      _c("p", { staticClass: "mb-1" }, [
                                        _c(
                                          "span",
                                          { staticClass: "stepTitle" },
                                          [_vm._v(_vm._s(_vm.$t("Steps[0]")))]
                                        ),
                                        _vm._v(_vm._s(_vm.$t("Step1Text"))),
                                      ]),
                                      _c("v-img", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          width: "120",
                                          src: require("../assets/images/qr_code.png"),
                                        },
                                      }),
                                      _c("p", { staticClass: "mb-1" }, [
                                        _c(
                                          "span",
                                          { staticClass: "stepTitle" },
                                          [_vm._v(_vm._s(_vm.$t("Steps[1]")))]
                                        ),
                                        _vm._v(_vm._s(_vm.$t("Step2Text"))),
                                      ]),
                                      _c("v-img", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          width: "180",
                                          src: require("../assets/images/tap_side_menu.png"),
                                        },
                                      }),
                                      _c(
                                        "v-row",
                                        { staticClass: "my-2" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "col-12 col-md-6" },
                                            [
                                              _c("p", { staticClass: "mb-1" }, [
                                                _c(
                                                  "span",
                                                  { staticClass: "stepTitle" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Steps[2]")
                                                      ) + ": "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  _vm._s(_vm.$t("Step3Text"))
                                                ),
                                              ]),
                                              _c("v-img", {
                                                staticClass: "mx-auto",
                                                attrs: {
                                                  width: "120",
                                                  src: require("@/assets/images/step34.png"),
                                                },
                                              }),
                                              _c("p", { staticClass: "mb-1" }, [
                                                _c(
                                                  "span",
                                                  { staticClass: "stepTitle" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Steps[3]")
                                                      ) + ":"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  _vm._s(_vm.$t("Step4Text"))
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { staticClass: "col-12 col-md-6" },
                                            [
                                              _c("p", { staticClass: "mb-1" }, [
                                                _c(
                                                  "span",
                                                  { staticClass: "stepTitle" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Steps[4]")
                                                      ) + ": "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  _vm._s(_vm.$t("Step5Text"))
                                                ),
                                              ]),
                                              _c("v-img", {
                                                staticClass: "mx-auto",
                                                attrs: {
                                                  width: "120",
                                                  src: require("@/assets/images/step5.png"),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-slide-item",
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "ma-4 pa-4",
                          attrs: {
                            color: "white",
                            "min-height": "300",
                            "min-width": "300",
                          },
                        },
                        [
                          _c("v-card-title", [
                            _c("h3", { staticClass: "text-center mb-0" }, [
                              _vm._v(_vm._s(_vm.$t("AboutUcarMobile"))),
                            ]),
                          ]),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                { staticStyle: { "max-width": "300px" } },
                                [
                                  _c("v-col", [
                                    _c(
                                      "div",
                                      [
                                        _c("p", { staticClass: "text-left" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("AboutUcarMobileText")
                                            )
                                          ),
                                        ]),
                                        _c("h3", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("WhyChooseUcarMobileTitle")
                                            )
                                          ),
                                        ]),
                                        _c("p", { staticClass: "text-left" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("WhyChooseUcarMobileText")
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { staticClass: "col-md-6" },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    display: "block",
                                                    margin: "auto",
                                                    width: "80%",
                                                  },
                                                  attrs: {
                                                    src: require("../assets/images/icons/warranty.png"),
                                                    alt: "Warranty",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "col-md-6",
                                                staticStyle: {
                                                  "align-self": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-center mb-0 green-text font-weight-bold",
                                                  },
                                                  [_vm._v("Customer Service")]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-center mb-0",
                                                  },
                                                  [_vm._v("214-972-9197")]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold text-center mb-0",
                                                  },
                                                  [_vm._v("www.ucarmobile.com")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "mb-1" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "stepTitle",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("Steps[5]")
                                                          ) + ": "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Step6Text")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c("v-img", {
                                                  staticClass: "mx-auto",
                                                  attrs: {
                                                    width: "120",
                                                    src: require("@/assets/images/step5.png"),
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mb-1 text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "stepTitle",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("Steps[6]")
                                                          ) + ": "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Step7Text")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          [
                                            _c("v-col", [
                                              _c("p", [
                                                _vm._v(
                                                  "* " +
                                                    _vm._s(
                                                      _vm.$t("ProvidingService")
                                                    )
                                                ),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-slide-item",
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "ma-4 pa-4",
                          attrs: {
                            color: "white",
                            "min-height": "300",
                            "min-width": "300",
                          },
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "px-0",
                              style: {
                                backgroundColor: "#003852",
                                width: "300px",
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("v-img", {
                                    staticClass: "mx-auto mt-10",
                                    attrs: {
                                      width: "200",
                                      src: require("../assets/images/icons/ucarmobile-card.png"),
                                      alt: "Ucarmobile",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-10",
                                      staticStyle: {
                                        "border-top": "5px solid #c2c2c2",
                                        "border-bottom": "5px solid #c2c2c2",
                                        "background-color": "rgb(18, 148, 95)",
                                      },
                                    },
                                    [
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "white--text text-center mx-auto",
                                          staticStyle: {
                                            width: "300px",
                                            "font-family": "'Rubik'",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "TrifoldThirdColumnTitle"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "col-12" },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "col-md-4 py-0" },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-10",
                                            attrs: {
                                              width: "100",
                                              src: require("../assets/images/icons/appServices/towing 2.svg"),
                                              alt: "Ucarmobile",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center white--text mt-0 mx-auto",
                                            },
                                            [_vm._v("Towing")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-md-4 py-0" },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-10",
                                            attrs: {
                                              width: "100",
                                              src: require("../assets/images/icons/appServices/oil change 2.svg"),
                                              alt: "Ucarmobile",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center white--text mt-0 mx-auto",
                                            },
                                            [_vm._v("Oil Change")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-md-4 py-0" },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-10",
                                            attrs: {
                                              width: "100",
                                              src: require("../assets/images/icons/appServices/glass replacement.svg"),
                                              alt: "Ucarmobile",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center white--text mt-0 mx-auto",
                                            },
                                            [_vm._v("Glass Replacement")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-md-4 py-0" },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-10",
                                            attrs: {
                                              width: "100",
                                              src: require("../assets/images/icons/appServices/mechanical repairs 2.svg"),
                                              alt: "Ucarmobile",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center white--text mt-0 mx-auto",
                                            },
                                            [_vm._v("Mechanical Repairs")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-md-4 py-0" },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-10",
                                            attrs: {
                                              width: "100",
                                              src: require("../assets/images/icons/appServices/headlight refurbishing 2.svg"),
                                              alt: "Ucarmobile",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center white--text mt-0 mx-auto",
                                            },
                                            [_vm._v("Headlight Refurbishing")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-md-4 py-0" },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-10",
                                            attrs: {
                                              width: "100",
                                              src: require("../assets/images/icons/appServices/detailing 2.svg"),
                                              alt: "Ucarmobile",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center white--text mt-0 mx-auto",
                                            },
                                            [_vm._v("Car Detailing")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-md-4 py-0" },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-10",
                                            attrs: {
                                              width: "100",
                                              src: require("../assets/images/icons/appServices/upholstery repair 2.svg"),
                                              alt: "Ucarmobile",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center white--text mt-0 mx-auto",
                                            },
                                            [_vm._v("Upholstery Repair")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-md-4 py-0" },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-10",
                                            attrs: {
                                              width: "100",
                                              src: require("../assets/images/icons/appServices/window tinting 2.svg"),
                                              alt: "Ucarmobile",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center white--text mt-0 mx-auto",
                                            },
                                            [_vm._v("Window Tinting")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-md-4 py-0" },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-10",
                                            attrs: {
                                              width: "100",
                                              src: require("../assets/images/icons/appServices/car odor removal 2.svg"),
                                              alt: "Ucarmobile",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center white--text mt-0 mx-auto",
                                            },
                                            [_vm._v("Car Odor Removal")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-md-4 py-0" },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-10",
                                            attrs: {
                                              width: "100",
                                              src: require("../assets/images/icons/appServices/paintless dent repair 2.svg"),
                                              alt: "Ucarmobile",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center white--text mt-0 mx-auto",
                                            },
                                            [_vm._v("Paintless dent repair")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-md-4 py-0" },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto mt-10",
                                            attrs: {
                                              width: "100",
                                              src: require("../assets/images/icons/appServices/locksmith 2.svg"),
                                              alt: "Ucarmobile",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center white--text mt-0 mx-auto",
                                            },
                                            [_vm._v("LockSmith")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "col-6 d-flex mx-auto justify-center pb-6",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "https://play.google.com/store/apps/details?id=com.uCarMobile.obdconnect",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "text-right",
                                                attrs: {
                                                  "max-width": "50",
                                                  src: require("../assets/images/icons/appServices/android.png"),
                                                  alt: "Ucarmobile Play Store",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "https://apps.apple.com/ie/app/ucar-mobile-car-care-repair/id1495701232?utm_source=ho[%E2%80%A6]&utm_campaign=ios_homepage_install&utm_id=iOS&platform=iphone",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "text-left",
                                                attrs: {
                                                  "max-width": "50",
                                                  src: require("../assets/images/icons/appServices/ios.png"),
                                                  alt: "Ucarmobile Apple Store",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }